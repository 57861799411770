<template>
  <loading-dialog :loading="$apollo.loading">
    <!-- bandeiras -->
    <app-general-retailer-flags :account-id="accountId" :app.sync="app" @refresh="refresh" />

    <!-- social login -->
    <app-general-social-login :account-id="accountId" :app.sync="app" @refresh="refresh" />

    <!-- recommended store -->
    <app-general-recommended-store-sheet :account-id="accountId" :app.sync="app" @refresh="refresh" />

    <!-- flags -->
    <app-general-flags :account-id="accountId" :app.sync="app" @refresh="refresh" />

    <!-- points -->
    <app-general-points :account.sync="account" :account-id="accountId" @refresh="refresh" />

    <!-- order offers -->
    <offers-order :account-id="accountId" @refresh="refresh" />

    <!-- store -->
    <app-general-stores :account-id="accountId" @refresh="refresh" />

    <!-- sharing -->
    <app-general-sharing :account-id="accountId" :account-name="accountName" @refresh="refresh" />

    <!-- update -->
    <app-general-update :account-id="accountId" @refresh="refresh" />

    <!-- force update -->
    <app-general-force-update :account-id="accountId" @refresh="refresh" />
  </loading-dialog>
</template>

<script>
import { QUERY_GET_ACCOUNT, QUERY_SINGLE_APP } from '@/modules/accounts/graphql'

export default {
  components: {
    LoadingDialog: () => import('@/components/base/LoadingDialog'),
    AppGeneralRetailerFlags: () => import('@/modules/accounts/components/app/AppGeneralRetailerFlags'),
    AppGeneralFlags: () => import('@/modules/accounts/components/app/AppGeneralFlags'),
    AppGeneralSocialLogin: () => import('@/modules/accounts/components/app/AppGeneralSocialLogin'),
    AppGeneralRecommendedStoreSheet: () => import('@/modules/accounts/components/app/AppGeneralRecommendedStoreSheet'),
    AppGeneralPoints: () => import('@/modules/accounts/components/app/AppGeneralPoints'),
    AppGeneralStores: () => import('@/modules/accounts/components/app/AppGeneralStores'),
    AppGeneralSharing: () => import('@/modules/accounts/components/app/AppGeneralSharing'),
    AppGeneralUpdate: () => import('@/modules/accounts/components/app/AppGeneralUpdate'),
    AppGeneralForceUpdate: () => import('@/modules/accounts/components/app/AppGeneralForceUpdate'),
    OffersOrder: () => import('@/modules/accounts/components/app/OffersOrder')
  },
  props: {
    accountId: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    app: null,
    account: null,
    accountName: ''
  }),
  apollo: {
    appConfig: {
      query: QUERY_SINGLE_APP,
      fetchPolicy: 'network-only',
      variables() {
        return { account_id: this.accountId }
      },
      update({ singleApp }) {
        this.app = singleApp
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['app_config']
        }
      }
    },
    adminAccount: {
      query: QUERY_GET_ACCOUNT,
      fetchPolicy: 'network-only',
      variables() {
        return { _id: this.accountId }
      },
      update({ adminAccount }) {
        if (!adminAccount) this.account = {}
        this.account = adminAccount
        this.accountName = adminAccount?.name
      }
    }
  },
  methods: {
    refresh() {
      this.$apollo.queries.appConfig.refetch()
      this.$apollo.queries.adminAccount.refetch()
    }
  }
}
</script>
